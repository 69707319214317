<template>
  <div class="herbariums">
    <div>
      <b-card body-class="text-center" header-tag="nav">
        <template v-slot:header>
          <b-nav card-header tabs>
            <b-nav-item to="/main/handbooks/plants">
              {{ $t('profile_herbariums_plants') }}
            </b-nav-item>
            <b-nav-item to="/main/handbooks/classification">
              {{ $t('profile_herbariums_classes') }}
            </b-nav-item>
            <b-nav-item to="/main/handbooks/images">
              {{ $t('profile_herbariums_images') }}
            </b-nav-item>
          </b-nav>
        </template>

        <div class="d-flex justify-content-between">
          <div class="text-left col-12 col-sm-6">
            <h5>{{ $t('profile_herbariums_handbooks_headline') }}</h5>
            <p class="my-3">
              {{ $t('profile_herbariums_handbooks_description') }}
            </p>
            <RoleRouterLink
              :to="'/main/handbooks/plants/add'"
              :name="$t('profile_herbariums_handbooks_btn')"
              :access="['editor', 'admin']"
              tag="button"
              class="success"
            />
          </div>
          <div class="col-0 col-sm-6 col-lg-4 d-flex">
            <img
              src="../../assets/profile/note-taking.svg"
              alt="alt"
              class="w-100"
            />
          </div>
        </div>
      </b-card>
    </div>
    <router-view class="mt-4" />
  </div>
</template>

<script>
import RoleRouterLink from '@/components/RoleRouterLink.vue'
export default {
  components: { RoleRouterLink }
}
</script>
<style lang="scss" scoped>
.herbariums {
  /deep/.card {
    border: none;
    nav {
      padding: 0;
      background-color: #f4f6f8;
      border: none;
      border-radius: 4px;
      ul {
        margin: 0;
        li {
          margin-bottom: 0;
          .router-link-active {
            background-color: #fff;
          }
          a {
            border: none;
            width: 165px;
            padding: 0.82rem 1rem;
            color: black;
            @media (max-width: 426px) {
              width: auto;
            }
          }
        }
      }
    }
    .card-body {
      border-radius: 0px 0px 4px 4px;
    }
    .success {
      color: #fff;
    }
  }
}
</style>
